@import "../../scss/imports";

.tab-content {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;

  h1 {
    font-size: 2rem;
  }
}

.tab-icon {
  vertical-align: text-bottom;
  margin-right: 0.5ex;
  height: 0.75em !important;
}
