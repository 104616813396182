@import "../../scss/imports";

.SignUpForm {
  .agree-row {
    justify-content: left;
  }

  .submit-button {
    @extend .btn-lg;
    @extend .btn-dark;
  }
}
