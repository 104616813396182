@import "../../scss/imports";

.SpinnerButton {

  // Make main div stretch to its contents, so that we can use width:100 % on button with spinner
  display: inline-block;

  .button-with-label {

    // When hidden, keep width so that spinner button stretches to button-with-label width
    // but do not keep height so that spinner button takes same spot as button-with-label
    &.hidden {
      visibility: hidden;
      height: 0;
    }

    &.visible {
      visibility: visible;
    }
  }

  .button-with-spinner {

    &.hidden {
      display: none;
    }

    &.visible {
      display: block;
    }

    // Stretch to match width of button with label
    button {
      width: 100%;
    }
  }
}
