@import "../../scss/imports";

.JobDropbox.dropzone {
  text-align: center;
  padding: 2rem;
  border-width: 2px;
  border-radius: $btn-border-radius;
  border-color: $logo-blue;
  border-style: dashed;
  background-color: $gray-100;
  color: $logo-green;
  outline: none;

  &:focus {
    border-color: $logo-blue;
  }

  &.disabled {
    background-color: $gray-300;
  }
}
