@import "../../scss/imports";

.CandidateListFilters {
  .card-body {
    background-color: $gray-300;
  }

  .contents {
    margin-left: 1rem;
  }
}
