@import "../../scss/imports";

// Round buttons with icon
.withRoundButton {
  &.btn {
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    padding: 0;
  }
}
