@import "../../scss/imports";

.ExpandableSection {

  // Space between immediate children
  &>* + * {
    margin-top: 1rem;
  }

  .header-row {
    .btn-link {
      padding: 0;
    }
  }
}
