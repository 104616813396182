@import "../../scss/imports";

.Page {

  // Set min-height of viewport (minus the fixed header) so that footer is pushed to bottom if main contents is
  // too small to fill entire viewport (minus the fixed header)
  .scrollable-content {
    display: flex;
    min-height: calc(100vh - #{$header-padding});
    flex-direction: column;
    justify-content: space-between;

    .main-container {
      // Add empty space between header and top of contents
      padding-top: 1rem;
      // Add empty space between bottom of contents and footer
      padding-bottom: 1rem;
    }
  }
}
