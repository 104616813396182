@import "../../scss/imports";

.FilterOption {
  .icon-col {
    padding-right: 0.25rem;
  }
  .option-col {
    padding-left: 0;
  }
}
