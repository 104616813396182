@import "../../scss/imports";

.JobOpeningsListItem {

  .SelectableItem {

    // When hovered, make black background a bit bigger than text
    $hpadding: $grid-gutter-width/4;
    margin-left: -$hpadding;
    margin-right: -$hpadding;
    padding-left: $hpadding;
    padding-right: $hpadding;

    &.unselected {
      &:hover {
        // Use same color as hovered dark button and same radius as button
        @extend .btn-dark;
        border-radius: $btn-border-radius;
      }
    }
    &.selected {
      @extend .btn-dark;
      border-radius: $btn-border-radius;
    }
  }
}
