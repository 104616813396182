@import "../../scss/imports";

.CandidateList {
  .refresh-col {
    text-align: right;
  }

  .button-icon {
    vertical-align: text-bottom;
    margin-right: 0.1em;
    height: 0.75em;
  }
}
