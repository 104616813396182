@import "../../scss/imports";

.PasswordForgetPage {
  // Center items horizontally
  text-align: center;
  .row {
    justify-content: center;
  }

  .portal_logo {
    max-width: 20%;
  }
}

.PasswordForgetBlock {
  &.card {
    border-color: $logo-blue;
  }

  .card-body {
    color: $logo-blue;
    text-align: center;
    border-radius: $btn-border-radius;
  }
}
