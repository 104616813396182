@import "../../scss/imports";

$footer-text-color: $gray-600;

.Footer {
  color: $footer-text-color;
  padding-top: 8px;
  padding-bottom: 8px;

  .row {
    //justify-content: space-between;
    align-items: center;
  }

  a {
    color: $footer-text-color;
  }

  .page-col {
    // Add horizontal space between items
    & > * + * {
      margin-left: 2rem;
    }
  }

  .copyright {
    font-size: 0.7rem;
    text-align: center;
  }

  .social {
    // Center alignment won't push social buttons to the far right but it will still look good if they are pushed
    // to the next row on smaller screens (right aligning them wouldn't look as good)
    text-align: center;
  }
}
