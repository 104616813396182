@import "../../scss/imports";

.JobOpeningsList {

  // Add space between direct list elements
  .card-body {
    &>.row + .row {
      margin-top: 1rem;
    }
  }

  .card-header {
    @extend .btn-dark;
    @extend .text-white;
    font-size: 1.5rem;

    // add button
    .add-button.btn-link {
      color: black;
      background-color: white;
    }

    .row {
      align-items: center;
    }
  }
}
