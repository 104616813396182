@import "../../scss/imports";

.AdminCompanyList {

  .item-card {
    border-color: $logo-blue;

    .item-card-body {
      padding: 0.75rem 1rem;

      .total-col {
        text-align: right;
      }
    }
  }
}
