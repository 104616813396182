@import "../../scss/imports";

.ThankYouPage {
  // Center items horizontally
  text-align: center;
  .row {
    justify-content: center;
  }

  .portal_logo {
    max-width: 20%;
  }
}

.ThankYouBlock {
  .row {
    margin-bottom: 20px;
  }
}
