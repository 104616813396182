@import "../../scss/imports";

.Menu {
  .logo {
    height: $menu-logo-height;
  }

  // Emulate "<a>" generated by Nav.Link (we don't use Nav.Link because we use React Router)
  .nav-link.nav-item {
    cursor: pointer;
    text-decoration: none;
  }

  .navbar-collapse {
    justify-content: flex-end;
  }

  .navbar-nav {
    align-items: flex-end;
  }

  .navbar-brand {
    .logo + .logo {
      margin-left: 1rem;
    }
  }

  // Shorten dropdown width because language dropdown will overflow to the right of the page
  .dropdown-menu {
    min-width: 1rem;

    .dropdown-item {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
