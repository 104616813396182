@import "../../scss/imports";

.SuccessModal {
  text-align: center;

  .modal-content {
    border-color: $success-green;
    border-width: thick;

    .status-icon {
      height: 5rem;
    }
  }
}
