@import "../../scss/imports";

.CandidateListItem {
  .card-header {
    font-weight: bold;
  }

  .skill-section {
    margin-left: 1rem;
  }

  .name-col {
    font-weight: bold;
  }
}

.SkillMatch {
  .label {
    font-weight: bold;
  }
}
